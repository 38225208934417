import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Checkbox, DatePicker, DatePickerProps, Form, GetProp } from "antd";
import FormField from "../../inputs/text/FormField";
import ToggleSwitch from "../../inputs/toggle/ToggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import { UsersService } from "../../../services/users/Users";
import { createUser, updateUser } from "../../../state/user/UserSlice";
import { addToast } from "../../../state/toast/ToastSlice";
import { GroupsService } from "../../../services/groups/Groups";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import PopoverItem from "../../tags/PopoverQuestionMark";
import SelectBox from "../../inputs/selectbox/SelectBox";
import FormButton from "../../buttons/FormButton";
import { setAside } from "../../../state/component/AsideSlice";
import { fetchSimcards } from "../../../state/simdata/SimcardsSlice";
import { RootState } from "../../../state/store";
import { ProjectsService } from "../../../services/project/Projects";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import { MailService } from "../../../services/mails/Mails";
import { useTranslation } from "react-i18next";

type User = {
  user: any;
};

const EditUserForm: FC<User> = ({ user }) => {
  const { t } = useTranslation();
  const service = new UsersService();
  const groupService = new GroupsService();
  const [form] = Form.useForm(); // Ant Design form instance
  const [userData, setUserData] = useState(user);
  const [onboarding, setOnboarding] = useState(false);
  const [options, setOptions] = useState([]);
  const [steps, setSteps] = useState([]);
  const [dueDate, setDueDate] = useState<string>(
    dayjs(new Date()).add(1, "week").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(true);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const dispatch = useDispatch();

  const projectService = new ProjectsService();
  const mailService = new MailService();

  useEffect(() => {
    setOnboarding(false);
    setFormLoading(true);

    // Perform asynchronous operation
    const fetchData = async () => {
      // Simulate an asynchronous operation with a timeout
      await fetchOptions();

      // Update groupData after the asynchronous operation
      setUserData(user);
      form.setFieldsValue(user);
      setFormLoading(false);
    };

    // Call the fetchData function to start the asynchronous operation
    fetchData();
  }, [user]);

  useEffect(() => {
    if (onboarding === true) {
      dispatch(fetchSimcards());
    }
  }, [onboarding]);

  const fetchOptions = async () => {
    const fetchedOptions = await groupService.getGroupNames(companyId);

    const formattedOptions = fetchedOptions.map((option) => ({
      ...option,
      searchLabel: option.searchLabel || option.label,
    }));

    setOptions(formattedOptions);
    setLoading(false);
  };

  const handleSelect = (value: string, label: string) => {
    const groupId = value;
    const groupName = label.label;

    setUserData((prev) => ({
      ...prev,
      geb_groep_id: groupId,
      bedrijf_groep: {
        bedGr_id: groupId,
        bedGr_naam: groupName,
      },
    }));
  };

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setUserData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const onUpdate = async (values: any) => {
    const { geb_groep_id } = userData;

    const geb_isActive = !!userData.geb_isActive === true ? 1 : 2;

    const updatedValues = {
      geb_groep_id,
      ...values,
      geb_isActive: geb_isActive,
      geb_isAdmin: geb_isActive === 1 ? !!userData.geb_isAdmin : false,
    };

    await service.updateUser(companyId, user.geb_id, updatedValues);

    dispatch(updateUser({ id: user.geb_id, updatedData: updatedValues }));

    dispatch(
      addToast({
        description: "De gebruiker is aangepast.",
        position: "bottomRight",
        style: "success",
      })
    );

    closeAside();
  };

  const onCreate = async (values: any) => {
    const randomPassword = generateRandomPassword();

    const { geb_groep_id, geb_isRapport, geb_isActive, geb_isAdmin } = userData;

    const updatedValues = {
      ...values,
      // password: randomPassword,
      geb_groep_id,
      geb_isRapport: geb_isRapport ?? false,
      geb_isActive: geb_isActive === 1 || geb_isActive === true ? 1 : 2,
      geb_isAdmin: geb_isActive ? geb_isAdmin ?? false : false,
    };

    const newUser = await service.createUser(companyId, updatedValues);

    dispatch(
      createUser({
        ...updatedValues,
        geb_id: newUser.userId,
        bedrijf_groep: userData.bedrijf_groep,
      })
    );

    dispatch(
      addToast({
        description: "De gebruiker is aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );

    if (onboarding) {
      const projectData = {
        proj_deadline: dueDate,
        proj_onderwerp: `Onboarding van ${updatedValues.geb_voornaam} ${updatedValues.geb_naam}`,
        proj_omschrijving: `Een project met de nodige stappen om ${updatedValues.geb_voornaam} ${updatedValues.geb_naam} te onboarden.`,
        proj_status: "Nieuw",
      };

      const tasks: ProjectTask[] = [];

      const stepDescriptions = {
        telecom: "Simkaart(en) selecteren",
        hardware: "Hardware toewijzen",
        licenties: "Licenties selecteren",
      };

      steps.forEach((step: string) => {
        const task: ProjectTask = {
          projFase_onderwerp: step,
          projFase_geschatte_tijd: 7,
          assignments: [
            {
              proj_omschrijving: stepDescriptions[step.toLowerCase()],
              projFaseRegel_type: step,
            },
          ],
        };

        tasks.push(task);
      });

      const newProject = {
        data: projectData,
        tasks,
        metadata: {
          userId: newUser.userId,
        },
      };

      const result = await projectService.createProject(newProject, companyId);

      navigate(`/project/detail/overview/?projectId=${result.result}`);

      const formData = new FormData();
      formData.append(
        "user",
        JSON.stringify({ ...updatedValues, geb_id: newUser.userId })
      );
      formData.append("deadline", dueDate);

      mailService.sendMail(
        "jorrit@cmconline.be",
        `Onboarding ${updatedValues.geb_voornaam} ${updatedValues.geb_naam}`,
        "email.project.projectstart",
        formData
      );

      dispatch(
        addToast({
          description: "Het onboarding project is gestart.",
          position: "bottomRight",
          style: "success",
        })
      );
    }
    closeAside();
  };

  const closeAside = () => {
    dispatch(
      setAside({
        active: 0,
      })
    );
  };

  const generateRandomPassword = (length: number = 10) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (
    checkedValues
  ) => {
    setSteps(checkedValues);
  };

  const onDate: DatePickerProps["onChange"] = (date, dateString) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setDueDate(formattedDate);
  };

  const plainOptions = [t("Telecom"), t("Hardware"), t("Licenties")];

  return (
    <div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <Form
          labelAlign="left"
          layout="vertical"
          form={form} // Pass Ant Design form instance to Form component
          initialValues={user} // Set initial values for form fields
          onFinish={Object.keys(user).length === 0 ? onCreate : onUpdate}
        >
          <div className="mt-5 grid grid-cols-3 gap-x-3">
            <div className="col-span-1">
              <FormField
                type="text"
                label="Naam"
                placeholder="John"
                name="geb_voornaam"
                form={form}
                isRequired={true}
              />
            </div>
            <div className="col-span-1">
              <FormField
                type="text"
                label="Familienaam"
                placeholder="Doe"
                name="geb_naam"
                form={form}
                isRequired={true}
              />
            </div>

            <div className="col-span-2">
              <FormField
                type="text"
                placeholder="john.doe@company.com"
                label="E-mailadres"
                name="email"
                form={form}
                isRequired={true}
              />
            </div>
            <div className="col-span-1 mt-auto pb-[24px]">
              <SelectBox
                options={options}
                defaultValue={
                  userData.geb_groep_id
                    ? userData.bedrijf_groep?.bedGr_naam
                    : t("Geen groep")
                }
                onChange={handleSelect}
                loading={loading}
              />
            </div>
            <div className="col-span-3 my-1 flex">
              <ToggleSwitch
                label="Gebruiker"
                checked={
                  userData.geb_isActive !== 1 && userData.geb_isActive !== true
                    ? false
                    : true
                }
                onClick={handleToggleChange}
                name="geb_isActive"
              />
              <PopoverItem
                description={
                  "Een gebruiker heeft toegang tot het platform, een medewerker niet."
                }
                title="Gebruiker"
              ></PopoverItem>
            </div>
            <div className="col-span-3 my-1 flex">
              <ToggleSwitch
                label="Administrator"
                checked={userData.geb_isAdmin}
                onClick={handleToggleChange}
                name="geb_isAdmin"
                disabled={!userData.geb_isActive}
              />
              <PopoverItem
                description={"Enkel gebruikers kunnen administrator zijn."}
                title="Admin"
              ></PopoverItem>
            </div>
            <div className="col-span-3 my-1">
              <ToggleSwitch
                label="Rapportage ontvangen"
                checked={userData.geb_isRapport}
                onClick={handleToggleChange}
                name="geb_isRapport"
              />
            </div>
            {Object.keys(user).length === 0 ? (
              <div className="col-span-3">
                <div className="my-1 flex">
                  <ToggleSwitch
                    label="Onboarden"
                    checked={onboarding}
                    onClick={() => setOnboarding(!onboarding)}
                    name="geb_isRapport"
                  />
                  <PopoverItem
                    description={
                      "Deze functie start een project om de gebruiker te onboarden met de geselecteerde onderdelen."
                    }
                    title="Onboarden"
                  ></PopoverItem>
                </div>
                <div className="col-span-3 my-1 flex flex-wrap items-center gap-3">
                  <Checkbox.Group
                    options={plainOptions}
                    defaultValue={["Apple"]}
                    onChange={onChange}
                    disabled={!onboarding}
                  />
                  <div className="flex items-center gap-3">
                    <p>{t("Tegen")}:</p>
                    <DatePicker
                      defaultValue={dayjs(new Date()).add(1, "week")}
                      onChange={onDate}
                      disabled={!onboarding}
                      format={"DD-MM-YYYY"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-span-3 my-2 gap-5">
              <div>
                <FormButton
                  form={true}
                  text={
                    Object.keys(user).length === 0
                      ? onboarding
                        ? "Onboarden"
                        : "Aanmaken"
                      : "Opslaan"
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default EditUserForm;
