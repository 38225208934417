import { ConfigProvider, Select } from "antd";
import React, { ChangeEvent, FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { Search } from "react-feather";

interface Option {
  label: React.ReactNode;
  value: string;
  searchLabel: string;
}

type Props = {
  onChange: (value: string, option: Option) => void;
  onSearch?: (searchTerm: string) => void;
  defaultValue: string;
  options: Option[];
  loading: boolean;
  props: {};
  search: boolean;
  notFoundText?: string;
  suffixIcon: ReactNode;
};

const SelectBox: FC<Props> = ({
  onChange,
  onSearch,
  defaultValue,
  options,
  loading,
  props,
  search = false,
  notFoundText,
  suffixIcon,
}) => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const handleSearch = (value: string) => {
    onSearch && onSearch(value);
  };

  const filterOption = (input: string, option: any) => {
    const normalizedInput = input.trim().toLowerCase().replace(/\s+/g, "");
    const normalizedSearchLabel = option?.searchLabel
      ?.trim()
      .toLowerCase()
      .replace(/\s+/g, "");

    return normalizedSearchLabel.includes(normalizedInput);
  };

  const filterSort = (optionA: any, optionB: any) => {
    const searchLabelA = optionA?.searchLabel || "";
    const searchLabelB = optionB?.searchLabel || "";
    return searchLabelA.toLowerCase().localeCompare(searchLabelB.toLowerCase());
  };

  return (
    <Select
      {...props}
      showSearch={search}
      options={options}
      defaultValue={defaultValue}
      onSelect={onChange}
      onSearch={handleSearch}
      className="w-full"
      loading={loading}
      filterOption={filterOption}
      filterSort={filterSort}
      notFoundContent={notFoundText}
      suffixIcon={suffixIcon}
    />
  );
};

export default SelectBox;
