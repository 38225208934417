import apiClient from "../../../api-client";

export class CouponService {
  public async getCoupon(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/coupons`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching coupon: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async getUserCoupon(id: number, companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/coupons/user/${id}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user coupon: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createCoupon(companyId: number, gebId: number, amount: number) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/coupons`,
        { geb_id: gebId, amount: Number(amount) }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating coupon: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async createCouponsGroup(
    companyId: number,
    users: Array<Object>,
    amount: number
  ) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/coupons/group`,
        { users: users, amount: Number(amount) }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating coupon: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateCoupon(
    companyId: number,
    couponId: number,
    amount: number,
    status: boolean
  ) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/coupons/${couponId}`,
        { cou_bedrag: amount, cou_used: status }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating coupon: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async deleteCoupon(companyId: number, couponId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/coupons/${couponId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting coupon: ${error.response?.statusText || error.message}`
      );
    }
  }

  //   public async getCoupons() {
  //     const response = await fetch(
  //         `${process.env.GATSBY_PORTAL_API_URL}/api/coupon`
  //       );
  //       return await response.json();
  //   }
}
