import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { ConfigProvider } from "antd";

const ThemeProvider = ({ children }) => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  return (
    <div className="max-w-full">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#1d4ed8",
            colorBgContainer: darkMode ? "#111827" : "transparent",
            colorBgContainerDisabled: darkMode ? "#334155" : "#f3f4f6",
            colorText: darkMode ? "#cbd5e1" : "#000",
            colorTextSecondary: darkMode ? "#cbd5e1" : "#000",
            colorTextTertiary: darkMode ? "#94a3b8" : "#9ca3af",
            colorTextQuaternary: darkMode ? "#cbd5e1" : "#000",
            colorTextDisabled: darkMode ? "#6b7280" : "#9ca3af",
            colorBorder: darkMode ? "#475569" : "#d1d5db",
            borderRadius: 8,
            colorBgElevated: darkMode ? "#374151" : "#fff",
            colorIcon: darkMode ? "#cbd5e1" : "#000",
            colorPrimaryBorderHover: "#1d4ed8",
          },
          components: {
            Slider: {
              railBg: darkMode ? "#334155" : "#f3f4f6",
              railHoverBg: darkMode ? "#334155" : "#f3f4f6",
              handleColor: "#1d4ed8",
            },
            Progress: {
              remainingColor: darkMode ? "#1e293b" : "#f3f4f6",
            },
            Tabs: {
              itemColor: darkMode ? "#6b7280" : "#9ca3af",
              itemActiveColor: darkMode ? "#cbd5e1" : "#000",
              itemSelectedColor: darkMode ? "#cbd5e1" : "#000",
              titleFontSize: 16,
              colorBorderSecondary: darkMode ? "#334155" : "#f3f4f6",
              lineWidth: 2,
              colorBgElevated: "#fff",
              colorBgContainer: darkMode ? "#111827" : "#fff",
            },
            DatePicker: {
              colorBgContainerDisabled: darkMode ? "#475569" : "#f3f4f6",
            },
            Menu: {
              colorText: "#fff",
              itemBg: "transparent",
              popupBg: "#1d4ed8",
              itemSelectedColor: "#fff",
              itemSelectedBg: "#1e40af",
              itemHoverColor: "#fff",
            },
            Select: {
              colorBgContainer: darkMode ? "#111827" : "white",
              optionSelectedBg: darkMode ? "#334155" : "#f3f4f6",
              colorBgElevated: darkMode ? "#1e293b" : "white",
              colorTextPlaceholder: darkMode ? "#e5e7eb" : "#6b7280",
              colorTextQuaternary: darkMode ? "#e5e7eb" : "#6b7280",
              multipleItemBg: darkMode ? "#334155" : "#f3f4f6",
              controlOutline: "#1d4ed8",
              controlOutlineWidth: 1,
              controlHeight: 34,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </div>
  );
};

export default ThemeProvider;
