import React, { ChangeEvent, FC, useState } from "react";
import { Form } from "antd";
import { CouponService } from "../../../services/coupons/CouponService";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../state/toast/ToastSlice";
import { createCoupon } from "../../../state/coupon/CouponSlice";
import FormButton from "../../buttons/FormButton";
import FormField from "../../inputs/text/FormField";
import { RootState } from "../../../state/store";

interface Props {
  user: any;
}

const AddCouponForm: FC<Props> = ({ user }) => {
  const [form] = Form.useForm();
  const service = new CouponService();
  const dispatch = useDispatch();

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const createCouponUser = async (value: any) => {
    const amount = value.bedrag;

    const couponData = await service.createCoupon(
      companyId,
      user.geb_id,
      amount
    );

    const coupon = {
      cou_bed_id: companyId,
      cou_bedrag: amount,
      cou_initial_value: amount,
      cou_geb_id: user.geb_id,
      cou_id: couponData.couponId,
    };

    dispatch(createCoupon(coupon));

    dispatch(
      addToast({
        description: "De coupon is aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  return (
    <Form
      labelAlign="left"
      layout="vertical"
      form={form}
      onFinish={createCouponUser}
    >
      <div>
        <FormField
          type="number"
          label="Bedrag (euro)"
          placeholder="0"
          name="bedrag"
          form={form}
          isRequired={true}
        />
        <FormButton
          form={true}
          text={Object.keys(user).length === 0 ? "Aanmaken" : "Opslaan"}
        />
      </div>
    </Form>
  );
};

export default AddCouponForm;
