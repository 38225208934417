import React, { FC, useEffect, useState } from "react";
import EditUserForm from "../../../components/forms/users/EditUserForm";
import Tabs from "../../../components/tabs/Tabs";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import UserHardware from "../../dashboard/user/UserHardware";
import UserSims from "../../dashboard/user/UserSims";
import { Button } from "antd";
import OffboardingForm from "../../../components/forms/offboarding/OffboardingForm";
import { AssetsService } from "../../../services/assets/Assets";
import { LicenseService } from "../../../services/licenses/Licenses";
import LoadingSpinner from "../../../components/buttons/LoadingSpinner";
import { UsersService } from "../../../services/users/Users";
import UserLicense from "../../users/licences/UserLicenses";
import Accordeon from "../../../components/accordeon/Accordeon";
import { useTranslation } from "react-i18next";

type User = {
  user: any;
};

const UserDetailContainer: FC<User> = ({ user }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const userState = useSelector((state: RootState) => state.aside.data);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const assetService = new AssetsService();
  const licenseService = new LicenseService();
  const userService = new UsersService();

  useEffect(() => {
    if (userState?.user?.geb_id && userState.user.geb_id !== currentUserId) {
      // Fetch data if the current user ID has changed
      setCurrentUserId(userState.user.geb_id);
      fetchData();
    }
  }, [userState, currentUserId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchall();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchall = async () => {
    const [userAssets, userLicenses, userSims] = await Promise.all([
      assetService.getUserAssets(companyId, userState.user.geb_id),
      licenseService.getUserLicenses(companyId, userState.user.geb_id),
      userService.getUserSims(companyId, userState.user.geb_id),
    ]);

    return {
      userAssets,
      userLicenses,
      userSims,
    };
  };

  const panels = [
    {
      key: "1",
      label: t("Simkaarten"),
      children: <UserSims sims={data?.userSims} />,
    },
    {
      key: "2",
      label: t("Hardware"),
      children: <UserHardware assets={data?.userAssets} />,
    },
    {
      key: "3",
      label: t("Licenties"),
      children: <UserLicense licenses={data?.userLicenses} />,
    },
    {
      key: "4",
      label: <span className=" text-red-700">{t("Offboarden")}</span>,
      children: <OffboardingForm user={userState.user} />,
    },
  ];

  const tabs = [
    {
      nav: "Overzicht",
      component: (
        <>
          <Accordeon panels={panels} activeKey={1} />
        </>
      ),
    },
    {
      nav: "Bewerken",
      component: [<EditUserForm user={userState.user} />],
    },
  ];

  return (
    <div>
      <h2 className=" text-2xl">
        <span className="block text-sm">
          {userState.user.geb_isActive === 1
            ? "Gebruiker"
            : userState.user.geb_isActive === 2
            ? "Medewerker"
            : "Inactief"}
        </span>
        {userState.user.geb_voornaam} {userState.user.geb_naam}
      </h2>
      {loading ? <LoadingSpinner /> : <Tabs tabs={tabs} />}
    </div>
  );
};

export default UserDetailContainer;
