import React, { useEffect, useState } from "react";
import { TarifService } from "../../services/simcards/Tarifs";
import Typography from "../../components/typography/Typography";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const TarifRoaming = ({ tarifId }) => {
  const tarifService = new TarifService();
  const companyId: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );
  const [roaming, setRoaming] = useState([""]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tarifId && companyId) {
      fetchRoaming();
    }
  }, [tarifId, companyId]);

  const fetchRoaming = async () => {
    setLoading(true);
    let tarifDetail = await tarifService.getTarif(tarifId, companyId);
    setRoaming(tarifDetail);
    setLoading(false);
  };

  return roaming.tarief_roaming != null ? (
    loading ? (
      <div className="m-3">
        <LoadingSpinner />
      </div>
    ) : (
      <div className="p-3">
        <Typography type="bold" tag="h4">
          {roaming.tarief_roaming.trf_typ_name} {roaming.trf_roaming_price}
        </Typography>
        <Typography type="label" tag="span">
          {roaming.tarief_roaming.trf_typ_countrys.info
            ? roaming.tarief_roaming.trf_typ_countrys.info
            : ""}
        </Typography>
        <div className="flex flex-wrap gap-1">
          {roaming.tarief_roaming.trf_typ_countrys.landen.map(
            (country, index) => (
              <Typography type="label" tag="span" addClass={"even:opacity-80"}>
                {country.name}
                {index <
                roaming.tarief_roaming.trf_typ_countrys.landen.length - 1
                  ? ","
                  : ""}
              </Typography>
            )
          )}
        </div>
      </div>
    )
  ) : (
    ""
  );
};

export default TarifRoaming;
