import React, { FC, useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { TruncateString } from "../../utils/text/TruncateString";

type DropdownItems = {
  items: (string | { label: string; to?: string; onClick?: () => void })[];
  button: string;
  onItemClick?: (item: string) => void;
};

const DropdownList: FC<DropdownItems> = ({ items, button, onItemClick }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (
    item: string | { label: string; onClick?: () => void }
  ) => {
    if (typeof item === "string") {
      if (onItemClick) onItemClick(item);
    } else if (item.onClick) {
      item.onClick();
    }
    setOpen(false);
  };

  return (
    <div ref={dropdownRef} className="hs-dropdown relative inline-flex z-30">
      <button
        onClick={toggle}
        className="hs-dropdown-toggle py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        {button}
        <svg
          className={`${open ? "rotate-180" : ""} transition-all w-4 h-4`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>

      <div
        className={`${
          open ? "block" : "hidden"
        } transition-opacity min-w-48 bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700 after:h-4 absolute right-0 top-10`}
        aria-labelledby="hs-dropdown-default"
      >
        {items.map((item, index) => (
          <div key={index}>
            {typeof item === "string" ? (
              <button
                className="w-full flex items-center gap-x-3.5 py-2 px-2 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                onClick={() => handleItemClick(item)}
              >
                {item}
              </button>
            ) : item.to ? (
              <Link
                to={item.to}
                className="w-full flex items-center gap-x-3.5 py-2 px-2 rounded-lg text-sm text-left text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                onClick={() => handleItemClick(item)}
              >
                {TruncateString({ text: item.label, limit: 20 })}
              </Link>
            ) : (
              <button
                className="w-full flex items-center gap-x-3.5 py-2 px-2 rounded-lg text-sm text-left text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                onClick={() => handleItemClick(item)}
              >
                {TruncateString({ text: item.label, limit: 20 })}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownList;
